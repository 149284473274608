import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import getFirebase, { authProvider } from "./utils/get-firebase";

import "./Login.css";

const firebase = getFirebase();

export default function Login(props) {
  const location = useLocation();

  if (props.user !== null && location.pathname === "/login") {
    return <Redirect to="/" />;
  }

  return (
    <div className="col center">
      <div className="Login col middle">
        <img
          src="/assets/meet-logo-white.svg"
          alt="DPS Meet"
          with={180}
          height={180}
        />
        <button
          className="large"
          onClick={() => {
            const googleAuthProvider = new authProvider.GoogleAuthProvider();
            firebase.auth().signInWithPopup(googleAuthProvider);
          }}
        >
          <span>Sign in with Google</span>
        </button>

        <button
          className="large"
          onClick={() => {
            firebase.auth().signInAnonymously();
          }}
        >
          Continue as Guest
        </button>
      </div>
    </div>
  );
}
