import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useRouteMatch,
} from "react-router-dom";

import { AuthContext } from "./AuthContext";
import Admin from "./Admin";
import Dashboard from "./Dashboard";
import Login from "./Login";
import Meeting from "./Meeting";

import getFirebase from "./utils/get-firebase";
import { registerUser, isAdmin as userIsAdmin } from "./utils/store-functions";

import "./App.css";

const firebase = getFirebase();

function App(props) {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unSubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user); // https://firebase.google.com/docs/reference/js/firebase.User
      } else {
        setUser(null);
      }
    });
    return function cleanup() {
      unSubscribe();
    };
  }, []);

  useEffect(() => {
    if (user && !user.isAnonymous)
      registerUser(user)
        .then(() => {
          // console.log("User registered");
        })
        .catch((error) => {
          console.error("Error registering user: ", error);
        });
  }, [user]);

  useEffect(() => {
    if (user && !user.isAnonymous) userIsAdmin(user, setIsAdmin);
  }, [user]);

  // @todo fix direct join link!

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <AuthContext.Consumer>
        {({ user }) => (
          <Router>
            <div className="App col center grow stretch">
              <Switch>
                <Route exact path="/">
                  {user ? (
                    <Dashboard user={user} isAdmin={isAdmin} />
                  ) : (
                    <Redirect to="/login" />
                  )}
                </Route>
                {isAdmin && (
                  <Route path="/admin">
                    {user ? (
                      <Admin user={user} isAdmin={isAdmin} />
                    ) : (
                      <Redirect to="/login" />
                    )}
                  </Route>
                )}
                <Route path="/join">
                  {user ? <Join user={user} /> : <Login />}
                </Route>
                <Route path="/login">
                  <Login user={user} />
                </Route>
                <Route path="*">
                  <div className="col center middle">
                    <h2>Looks like there's nothing here.</h2>
                    <Link to="/login" className="button">
                      Login
                    </Link>
                  </div>
                </Route>
              </Switch>
            </div>
          </Router>
        )}
      </AuthContext.Consumer>
    </AuthContext.Provider>
  );
}

function Join({ user }) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <div className="col center middle">
          <h1>No meeting to join here</h1>
        </div>
      </Route>
      <Route path={`${path}/:roomName`}>
        <Meeting user={user} />
      </Route>
    </Switch>
  );
}

export default App;
