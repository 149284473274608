import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  NavLink,
  Redirect,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import Navbar from "./Navbar";
import RoomSettings from "./admin/RoomSettings";
import { db, getUsers, getRooms } from "./utils/store-functions";

import "./Admin.css";

export default function Admin(props) {
  const [rooms, setRooms] = useState([]);
  const [users, setUsers] = useState([]);
  const history = useHistory();

  let { path, url } = useRouteMatch();

  useEffect(() => {
    const unSubscribe = getRooms(null, setRooms);
    return function cleanup() {
      unSubscribe();
    };
  }, []);

  useEffect(() => {
    const unSubscribe = getUsers(null, setUsers);
    return function cleanup() {
      unSubscribe();
    };
  }, []);

  const addRoom = () => {
    db.collection("rooms")
      .add({
        name: "",
        moderators: [],
        locked: false,
      })
      .then((docRef) => {
        history.push(`${url}/rooms/${docRef.id}`);
      })
      .catch((error) => {
        console.error("Error adding room: ", error);
      });
  };

  //if (redirectTo) return <Redirect to={redirectTo} />;

  const roomList = rooms.map((room) => (
    <NavLink to={`${url}/rooms/${room.id}`} key={room.id} className="row">
      <span className="grow">{room.name || "[New Room]"}</span>
      {room.locked && (
        <img
          src="/assets/icon-lock-line.svg"
          alt="Locked"
          width={17}
          height={20}
        />
      )}
    </NavLink>
  ));

  const userList = users.map((user) => (
    <NavLink to={`${url}/user/${user.uid}`} key={user.uid} className="col">
      {user.displayName}
      <span className="small">{user.email}</span>
    </NavLink>
  ));

  return (
    <>
      <Navbar user={props.user} isAdmin={props.isAdmin} headline="Admin area" />
      <div className="Admin row center">
        <div className="Side col list">
          <NavLink to={`${url}/rooms`}>Rooms</NavLink>
          <NavLink to={`${url}/user`}>User</NavLink>
        </div>

        <div className="Main row">
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${url}/rooms`} />
            </Route>
            <Route path={`${path}/rooms`}>
              <Switch>
                <Route exact path={`${path}/rooms`}>
                  <div className="Rooms col list">
                    {roomList}
                    &nbsp;
                    <button className="small" onClick={addRoom}>
                      Add room
                    </button>
                  </div>
                  <div className="Room" />
                </Route>
                <Route path={`${path}/rooms/:roomId`}>
                  <div className="Rooms col list">
                    {roomList}
                    &nbsp;
                    <button className="small" onClick={addRoom}>
                      Add room
                    </button>
                  </div>
                  {/* @todo This use of all users needs to be refactored when the list grows longer */}
                  <RoomSettings userList={users} />
                </Route>
              </Switch>
            </Route>
            <Route path={`${path}/user`}>
              <Switch>
                <Route exact path={`${path}/user`}>
                  <div className="Users col list">{userList}</div>
                  <div className="User" />
                </Route>
                <Route path={`${path}/user/:userId`}>
                  <div className="Users col list">{userList}</div>
                  <div className="User" />
                </Route>
              </Switch>
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
}
