import React from "react";

export default class Jitsi extends React.Component {
  constructor(props) {
    super(props);
    this.api = null;
  }

  componentDidMount() {
    this.api = new window.JitsiMeetExternalAPI("8x8.vc", {
      roomName:
        "vpaas-magic-cookie-5e32f93bc98d434e9b6bef15f383507d/" +
        this.props.roomName,
      parentNode: document.querySelector("#meet-container"),
      userInfo: {
        displayName: this.props.displayName,
      },
      configOverwrite: {
        brandingRoomAlias: this.props.roomName,
        prejoinPageEnabled: this.props.prejoinPageEnabled ?? true,
        //enableReactions: true // no visible effect
        enableSaveLogs: false,
        enableLayerSuspension: true,
        disableAudioLevels: false, // Seems to help with some performance issues https://community.jitsi.org/t/reducing-resource-usage-to-improve-performance-both-client-side-and-server-side/39891
        //resolution: 480, // reduce video resolution to SD by default
        constraints: {
          video: {
            height: {
              ideal: 480, // reduce video resolution to SD by default
              max: 720,
              min: 240,
            },
          },
        },
        desktopSharingFrameRate: {
          min: 5,
          max: 30,
        },
        liveStreamingEnabled: false,
        transcribingEnabled: false,
        fileRecordingsEnabled: true,
        dropbox: false,
      },
      interfaceConfigOverwrite: {
        DISABLE_VIDEO_BACKGROUND: true,
        DISABLE_FOCUS_INDICATOR: true,
        DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
        CONNECTION_INDICATOR_DISABLED: true
      },
      jwt: this.props.jwt,
    });

    this.api.addListener("readyToClose", () => {
      this.api.dispose();
      this.api = null;
      this.props.onHangUp && this.props.onHangUp();
    });

    this.api.addListener("displayNameChange", ({ id, displayname }) => {
      if (id === "local" && this.props.onChangeDisplayName)
        this.props.onChangeDisplayName(displayname);
    });
  }

  componentWillUnmount() {
    if (this.api) {
      this.api.dispose();
    }
  }

  render() {
    return <div id="meet-container" />;
  }
}
