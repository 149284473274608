import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import AutoComplete from "../components/AutoComplete";
import {
	db,
	getRoom,
	setRoom as dbSetRoom,
	slugForUrl,
	slugForId,
	isRoomNameAlreadyTaken,
} from "../utils/store-functions";

export default function RoomSettings(props) {
	const { roomId } = useParams();
	const [room, setRoom] = useState(null);
	const [newRoomName, setNewRoomName] = useState(room ? room.name : "");
	const [roomNameIsTaken, setRoomNameIsTaken] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isLookingForMember, setIsLookingForMember] = useState(false);
	const [isLookingForModerator, setIsLookingForModerator] = useState(false);
	const [memberToAdd, setMemberToAdd] = useState(null);
	const [moderatorToAdd, setModeratorToAdd] = useState(null);
	const [copiedToClipboard, setCopiedToClipboard] = useState(false);
	const history = useHistory();

	const currentSlug = room ? room.slugId : undefined;

	useEffect(() => {
		// Since navigating to a new room only re-renders this component with a new `roomId`, we need to reset state manually
		setIsLookingForMember(false);
		setIsLookingForModerator(false);
		setNewRoomName("");
		setRoomNameIsTaken(false);
		const unSubscribe = getRoom(roomId, setRoom);
		return function cleanup() {
			unSubscribe();
		};
	}, [roomId]);

	useEffect(() => {
		if (room) setNewRoomName(room.name);
	}, [room]);

	useEffect(() => {
		if (
			newRoomName &&
			newRoomName !== "" &&
			currentSlug !== slugForId(newRoomName)
		) {
			isRoomNameAlreadyTaken(newRoomName, setRoomNameIsTaken);
		} else {
			setRoomNameIsTaken(false);
		}
		// TODO db.collection().get() returns a simple Promise which is not easy to cancel, so we should look for a different way to clean up the useEffect hook when the component get's unmounted
	}, [newRoomName, currentSlug]);

	if (!room)
		return <div className="Room">There is no room with this ID.</div>;

	const saveRoom = (room) => {
		if (roomNameIsTaken) {
			window.alert("This room name is already in use");
		} else {
			setIsSaving(true);
			room.slugUrl = slugForUrl(room.name);
			room.slugId = slugForId(room.name);
			// Ensure that moderators are always in the member list
			if(!room.members) room.members = [];
			if(!room.moderators) room.moderators = [];
			room.members = room.members.concat(room.moderators.filter((uid) => room.members.indexOf(uid) < 0));
			dbSetRoom(roomId, room)
				.then(() => {
					setIsSaving(false);
				})
				.catch((error) => {
					console.error("Error writing document: ", error);
					setIsSaving(false);
				});
		}
	};

	const addMember = () => {
		if (memberToAdd) {
			const roomData = { ...room };
			if (!roomData.members) roomData.members = [];
			roomData.members.push(memberToAdd.uid);
			saveRoom(roomData);
			setIsLookingForMember(false);
		}
	};

	const addModerator = () => {
		if (moderatorToAdd) {
			const roomData = { ...room };
			if (!roomData.moderators) roomData.moderators = [];
			roomData.moderators.push(moderatorToAdd.uid);
			saveRoom(roomData);
			setIsLookingForModerator(false);
		}
	};

	const removeMember = (index) => {
		const roomData = { ...room };
		roomData.members.splice(index, 1);
		saveRoom(roomData);
	};

	const removeModerator = (index) => {
		const roomData = { ...room };
		roomData.moderators.splice(index, 1);
		saveRoom(roomData);
	};

	const deleteRoom = () => {
		if (
			window.confirm(
				`When you are sure you want to permanently delete ${room.name}, click OK.`
			)
		) {
			db.collection("rooms")
				.doc(roomId)
				.delete()
				.then(() => {
					history.push("/admin/rooms");
				})
				.catch((error) => {
					console.error("Error removing document: ", error);
				});
		}
	};

	const toggleLocked = () => {
		const roomData = { ...room };
		roomData.locked = !roomData.locked;
		saveRoom(roomData);
	};

	const members =
		room.members &&
		room.members.map((memberId, index) => {
			const user = props.userList.find((u) => u.uid === memberId);
			return (
				<div className="row list-item middle" key={memberId}>
					<div className="grow">
						{user ? user.displayName : memberId}
					</div>
					<button
						className="small icon row middle round"
						onClick={() => {
							removeMember(index);
						}}
					>
						<img
							src="/assets/icon-remove-fill.svg"
							with={20}
							height={20}
							alt="Remove"
						/>
					</button>
				</div>
			);
		});

	const moderators =
		room.moderators &&
		room.moderators.map((moderatorId, index) => {
			const user = props.userList.find((u) => u.uid === moderatorId);
			return (
				<div className="row list-item middle" key={moderatorId}>
					<div className="grow">
						{user ? user.displayName : moderatorId}
					</div>
					<button
						className="small icon row middle round"
						onClick={() => {
							removeModerator(index);
						}}
					>
						<img
							src="/assets/icon-remove-fill.svg"
							with={20}
							height={20}
							alt="Remove"
						/>
					</button>
				</div>
			);
		});

	let potentialMembers = props.userList;
	if (room.members && room.members.length > 0) {
		potentialMembers = props.userList.filter((user) => {
			return (
				room.members.findIndex((memberId) => memberId === user.uid) < 0
			);
		});
	}

	let potentialModerators = props.userList;
	if (room.moderators && room.moderators.length > 0) {
		props.userList.filter((user) => {
			return (
				room.moderators.findIndex(
					(moderatorId) => moderatorId === user.uid
				) < 0
			);
		});
	}

	return (
		<div className="Room col">
			<div className="row">
				<label className="grow">
					Room Name
					<input
						type="text"
						placeholder="Enter room name"
						value={newRoomName}
						onChange={(e) => setNewRoomName(e.target.value)}
					/>
				</label>
				&emsp;
				<button
					className="Save"
					disabled={
						room.name === newRoomName || roomNameIsTaken || isSaving
					}
					onClick={() => {
						const roomData = { ...room, name: newRoomName };
						saveRoom(roomData);
					}}
				>
					{isSaving ? "..." : "Save"}
				</button>
			</div>
			{roomNameIsTaken && (
				<span className="warning">
					This name is too similar to an existing room. Please use a
					different name.
				</span>
			)}
			&nbsp;
			{room.slugUrl && (
				<div className="row middle">
					<label className="grow">
						Meeting Link
						<Link
							to={`/join/${room.slugUrl}`}
						>{`${window.location.origin}/join/${room.slugUrl}`}</Link>
					</label>
					<button
						className="Save"
						onClick={() => {
							navigator.clipboard
								.writeText(
									`${window.location.origin}/join/${room.slugUrl}`
								)
								.then(
									() => {
										setCopiedToClipboard(true);
										setTimeout(
											() => setCopiedToClipboard(false),
											2000
										);
									},
									(err) =>
										window.alert(
											`Could not copy link. ${err}`
										)
								);
						}}
					>
						{copiedToClipboard ? "✔︎" : "Copy"}
					</button>
				</div>
			)}
			&nbsp;
			<div>
				<label>
					Lock this room?
					<div className="row">
						<div className="grow">
							Only members will have access when a room is locked
						</div>
						<input
							type="checkbox"
							checked={room.locked}
							onChange={toggleLocked}
						/>
					</div>
				</label>
			</div>
			&nbsp;
			{room.locked && (
				<div className="Members">
					<label>Members</label>
					{members}
					{isLookingForMember ? (
						<div className="row">
							<AutoComplete
								className="grow"
								label="Add new member"
								items={potentialMembers}
								onChange={(event) => {
									if (event.selectedItem) {
										setMemberToAdd(event.selectedItem);
									}
								}}
							/>
							&emsp;
							<button
								className="Save"
								disabled={!memberToAdd}
								onClick={addMember}
							>
								Add
							</button>
						</div>
					) : (
						<button
							className="small align-self-start"
							onClick={() => setIsLookingForMember(true)}
						>
							Add member
						</button>
					)}
				</div>
			)}
			&nbsp;
			<div className="Moderators">
				<label>Moderators (automatically added to Members)</label>
				{moderators}
				{isLookingForModerator ? (
					<div className="row">
						<AutoComplete
							className="grow"
							label="Add new moderator"
							items={potentialModerators}
							onChange={(event) => {
								if (event.selectedItem) {
									setModeratorToAdd(event.selectedItem);
								}
							}}
						/>
						&emsp;
						<button
							className="Save"
							disabled={!moderatorToAdd}
							onClick={addModerator}
						>
							Add
						</button>
					</div>
				) : (
					<button
						className="small align-self-start"
						onClick={() => setIsLookingForModerator(true)}
					>
						Add moderator
					</button>
				)}
			</div>
			&nbsp;
			<label>
				Delete Room
				<div>Delete this room and all attached data?</div>
				<button className="small align-self-start" onClick={deleteRoom}>
					Delete room
				</button>
			</label>
		</div>
	);
}
