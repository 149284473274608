import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import { getRooms, isPrivileged, slugForUrl } from "./utils/store-functions";
import useStickyState from "./utils/use-sticky-state";

import "./Dashboard.css";

export default function Dashboard(props) {
  const [newRoomName, setNewRoomName] = useState("");
  //const [password, setPassword] = useState("");
  const [publicRooms, setPublicRooms] = useState([]);
  const [lockedRooms, setLockedRooms] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [layout, setLayout] = useStickyState("grid");
  const { user } = props;
  const isDPS = isPrivileged(user);

  useEffect(() => {
    // Get a list of all the unlocked rooms (everyone has access, including anonymous)
    const unSubscribe = getRooms(["locked", "==", false], setPublicRooms);
    return function cleanup() {
      unSubscribe();
    };
  }, []);

  useEffect(() => {
    // Get a list of private/locked rooms (only known users in the members list have access)
    const unSubscribe = getRooms(
      [
        ["locked", "==", true],
        ["members", "array-contains", user.uid],
      ],
      setLockedRooms
    );
    return function cleanup() {
      unSubscribe();
    };
  }, [user]);

  useEffect(() => {
    // Combine public rooms and locked rooms and sort the list again
    const roomsUpdate = publicRooms.concat(lockedRooms);
    roomsUpdate.sort((a, b) => {
      return a.name.localeCompare(b.name, "en", {
        numeric: true,
        sensitivity: "base",
      });
    });
    setRooms(roomsUpdate);
  }, [publicRooms, lockedRooms]);

  const roomList = rooms.map((room) =>
    layout === "grid" ? (
      <div className="Room-Wrapper" key={room.id}>
        <Link
          to={`/join/${room.slugUrl || slugForUrl(room.name)}`}
          className="Room"
        >
          <div className="vibrant col center grow">
            <h1>
              {room.locked ? (
                <>
                  <img
                    src="/assets/icon-lock-fill.svg"
                    alt="Locked"
                    width={14}
                  />
                  <br />                  
                </>
              ) : null}
              {room.name}              
            </h1>
          </div>
          <div className="join">Join</div>
        </Link>
      </div>
    ) : (
      <div className="Room-Wrapper list-item" key={room.id}>
        <Link
          to={`/join/${room.slugUrl || slugForUrl(room.name)}`}
          className="Room row middle"
        >
          &thinsp;
          <div className="Circle vibrant col center middle">
            {room.locked ? (
              <img src="/assets/icon-lock-fill.svg" alt="Locked" width={14} />
            ) : (
              <span />
            )}
          </div>
          &ensp;
          <h1 className="grow">{room.name}</h1>
          <div className="join">Join</div>
        </Link>
      </div>
    )
  );

  return (
    <>
      <Navbar
        user={user}
        isAdmin={props.isAdmin}
        headline={`Select a room to join a meeting ${
          isDPS ? "or create your own" : ""
        }`}
        layout={layout}
        onChangeLayout={setLayout}
      />

      <div className="Dashboard col center">
        <div
          className={`Rooms ${
            layout === "grid" ? "Rooms-Grid row center wrap" : "Rooms-List col"
          }`}
        >
          {isDPS && (
            <div className="Room-Wrapper">
              <div
                className={`Room Create ${layout === "grid" ? "" : "row end"}`}
              >
                <div className="col center grow padding-horizontal">
                  <span>Create a temporary meeting room</span>
                  <label>
                    Meeting Subject
                    <input
                      type="text"
                      placeholder="Enter a title or topic"
                      value={newRoomName}
                      onChange={(e) => setNewRoomName(e.target.value)}
                    />
                  </label>
                </div>
                <Link
                  to={`/join/${slugForUrl(newRoomName)}`}
                  className={`${layout === "grid" ? "join" : "button"}`}
                >
                  Create
                </Link>
              </div>
            </div>
          )}
          {roomList}
        </div>
      </div>
    </>
  );
}
