import slug from "slug";
import getFirebase from "./get-firebase";
const firebase = getFirebase();
const db = firebase.firestore();

export { db };

export function registerUser(user) {
    return db
        .collection("users")
        .doc(user.uid)
        .set(
            {
                uid: user.uid,
                displayName: user.displayName,
                email: user.email,
                emailVerified: user.emailVerified,
                phoneNumber: user.phoneNumber,
                photoURL: user.photoURL,
            },
            { merge: true }
        );
}

export function isAdmin(user, callback) {
    return db
        .collection("settings")
        .doc("roles")
        .get()
        .then((doc) => {
            if (doc.exists) callback(true); // If we can read the doc, then we're an admin
        })
        .catch((error) => {
            // console.log("Error getting document:", error);
            // If the error is "FirebaseError: Missing or insufficient permissions.", then we're not an admin
            callback(false);
        });
}

export function getUsers(where = null, callback) {
    return db
        .collection("users")
        .orderBy("displayName")
        .onSnapshot((querySnapshot) => {
            const usersUpdate = [];
            querySnapshot.forEach((doc) => {
                usersUpdate.push(doc.data());
            });
            callback(usersUpdate);
        });
}

export function getRooms(where = null, callback) {
    let query = db.collection("rooms");

    if (Array.isArray(where)) {
        // We have conditions that should be applied, each condition must be an array with 3 values
        // See: https://firebase.google.com/docs/firestore/query-data/queries
        if (Array.isArray(where[0])) {
            // We have more than one condition: `where` is an array of arrays
            where.forEach((condition) => {
                query = query.where(...condition);
            });
        } else {
            // We have a single condition
            query = query.where(...where);
        }
    }

    return query.orderBy("name").onSnapshot((querySnapshot) => {
        const roomsUpdate = [];
        querySnapshot.forEach((doc) => {
            roomsUpdate.push({ ...doc.data(), id: doc.id });
        });
        roomsUpdate.sort((a, b) => {
            return a.name.localeCompare(b.name, "en", {
                numeric: true,
                sensitivity: "base",
            });
        });
        callback(roomsUpdate);
    });
}

export function getRoom(roomId, callback) {
    return db
        .collection("rooms")
        .doc(roomId)
        .onSnapshot((doc) => {
            if (doc.exists) {
                const room = { ...doc.data(), id: roomId };
                callback(room);
            } else {
                callback(null);
            }
        });
}

export function getRoomByName(roomName, callback) {
    const slug = slugForUrl(roomName);
    return db
        .collection("rooms")
        .where("slug", "==", slug)
        .get()
        .then((querySnapshot) => {
            if (querySnapshot.empty) {
                alert("No matching room.");
                return null;
            } else {
                querySnapshot.forEach((doc) => {
                    callback(doc);
                });
            }
        })
        .catch((error) => {
            console.log("Error searching taken room names: ", error);
        });
}

export function setRoom(roomId, data) {
    return db
        .collection("rooms")
        .doc(roomId)
        .set(data);
}

export function isPrivileged(user) {
    if (!user) return false;
    if (!user.email) return false;
    if (user.isAnonymous) return false;

    return (
        user.email.indexOf("@dpschool.io") > -1 ||
        user.email.indexOf("@unternehmertum.de") > -1
    );
}

export function slugForUrl(name) {
    return slug(name, { lower: false });
}

export function slugForId(name) {
    return slug(name);
}

export function isRoomNameAlreadyTaken(roomName, callback) {
    const slugId = slugForId(roomName);
    return db
        .collection("rooms")
        .where("slugId", "==", slugId)
        .get()
        .then((querySnapshot) => {
            if (querySnapshot.empty) {
                callback(false);
            } else {
                callback(true);
            }
        })
        .catch((error) => {
            console.log("Error searching taken room names: ", error);
        });
}
