import React from "react";
import { Link } from "react-router-dom";
import getFirebase from "./utils/get-firebase";

import "./Navbar.css";

const firebase = getFirebase();

export default function Navbar(props) {
  return (
    <div className="Navbar row middle">
      <header className="row middle grow">
        <Link to="/">
          <img src="/assets/meet-logo-white.svg" alt="DPS Meet" height={50} />
        </Link>
        <span>{props.headline}</span>
      </header>
      {props.layout && (
        <div className="row middle grow">
          <button className={`${props.layout === "grid" ? "active" : ""}`} onClick={() => props.onChangeLayout('grid')}>
            Grid
          </button>
          &ensp;
          <button className={`${props.layout === "list" ? "active" : ""}`} onClick={() => props.onChangeLayout('list')}>
            List
          </button>
        </div>
      )}
      {props.user && (
        <>
          <div className="User row middle">
            {props.user.isAnonymous ? (
              <span className="Name">Guest</span>
            ) : (
              <span className="Name">{props.user.displayName}</span>
            )}
            {props.user.photoURL && (
              <div className="Avatar row center">
                <img
                  src={props.user.photoURL}
                  alt="User Avatar"
                  width={50}
                  height={50}
                />
              </div>
            )}
          </div>
          {props.isAdmin && (
            <Link to="/admin" className="button">
              Admin
            </Link>
          )}
          <button
            className="large"
            onClick={() => {
              firebase.auth().signOut();
            }}
          >
            Logout
          </button>
        </>
      )}
    </div>
  );
}
