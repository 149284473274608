import React, { useState } from "react";
import { useCombobox } from "downshift";

import "./AutoComplete.css";

export default function AutoComplete(props) {
	const [inputItems, setInputItems] = useState(props.items);
	const {
		isOpen,
		//getToggleButtonProps,
		getLabelProps,
		getMenuProps,
		getInputProps,
		getComboboxProps,
		highlightedIndex,
		getItemProps,
	} = useCombobox({
		items: inputItems,
		itemToString: (item) => (item ? item.displayName : ""),
		onInputValueChange: ({ inputValue }) => {
			setInputItems(
				props.items.filter((item) =>
					item.displayName
						.toLowerCase()
						.startsWith(inputValue.toLowerCase())
				)
			);
		},
		onSelectedItemChange: props.onChange,
	});
	return (
		<div className={props.className}>
			<label {...getLabelProps()}>
				{props.label}
				<div className="col stretch" {...getComboboxProps()}>
					<input {...getInputProps()} />
					{/*<button
					type="button"
					{...getToggleButtonProps()}
					aria-label="toggle menu"
				>
					&#8595;
				</button>*/}
				</div>
			</label>
			<ul {...getMenuProps()} className="PopOver">
				{isOpen &&
					inputItems.map((item, index) => (
						<li
							className="col"
							style={
								highlightedIndex === index
									? { backgroundColor: "#ff8c1a80" }
									: {}
							}
							key={`${item.uid}`}
							{...getItemProps({ item, index })}
						>
							{item.displayName}
							<span className="small">{item.email}</span>
						</li>
					))}
			</ul>
		</div>
	);
}
