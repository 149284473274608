import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import getFirebase from "./utils/get-firebase";
import Jitsi from "./Jitsi";
const firebase = getFirebase();

async function postData(url = "", authToken, data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export default function Meeting(props) {
  const [displayName, setDisplayName] = useState(
    (props.user && props.user.displayName) || "Guest"
  );
  const [prejoinPageEnabled, setPrejoinPageEnabled] = useState(true);
  const [callHasEnded, setCallHasEnded] = useState(false);
  const [userJWT, setUserJWT] = useState(null);
  const [error, setError] = useState(null);
  let { roomName } = useParams();

  useEffect(() => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(function(idToken) {
        postData(
          "https://europe-west3-meet-dps.cloudfunctions.net/getJWT",
          //"http://localhost:5001/meet-dps/europe-west3/getJWT",
          idToken,
          {
            userName: displayName,
            roomName: roomName ? roomName : "Lounge",
          }
        )
          .then((data) => {
            if (data.status && data.status === 200) {
              if (data.token) setUserJWT(data.token);
            } else {
              setError(data);
            }
          })
          .catch(function(error) {
            setError({ message: error });
            console.error("Could not get JWT", error);
          });
      })
      .catch(function(error) {
        console.error("Could not get Firebase ID token", error);
      });
  }, [displayName, roomName]);

  // The URL is missing a room slug, let's redirect to the Dashboard
  if (!roomName) return <Redirect to="/" />;

  // We have an error, let's inform the user
  if (error && error.message) {
    return (
      <div className="col center middle">
        {error.status === 401 && (
          <img src="/assets/icon-lock-fill.svg" alt="Locked" width={100} />
        )}
        <h2>{error.message}</h2>
      </div>
    );
  }

  // The call has ended, what to do next?
  if (callHasEnded) {
    if (props.user.isAnonymous) {
      return (
        <div className="col center middle">
          <h2>Thank you for meeting with us.</h2>
          <button
            onClick={() => {
              setPrejoinPageEnabled(false);
              setCallHasEnded(false);
            }}
          >
            Rejoin the call
          </button>
        </div>
      );
    } else {
      // TODO Design the "after call" experience for regular users
      return <Redirect to="/" />;
    }
  }

  // We're still trying to get into the call
  return userJWT ? (
    <Jitsi
      roomName={roomName}
      displayName={displayName}
      prejoinPageEnabled={prejoinPageEnabled}
      jwt={userJWT}
      onChangeDisplayName={setDisplayName}
      onHangUp={() => {
        setCallHasEnded(true);
      }}
    />
  ) : (
    <div className="col center middle">
      <div className="Loader" />
    </div>
  );
}
