import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import firebaseConfig from "../config/firebase-config.json";

// nice tutorial for this approach: https://designcode.io/react-hooks-handbook-intro-to-firebase

let instance;

export default function getFirebase() {
    if (typeof window !== "undefined") {
        if (instance) return instance;
        instance = firebase.initializeApp(firebaseConfig);
        return instance;
    }

    return null;
}

export const authProvider = firebase.auth;